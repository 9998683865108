<template>
  <div class="course-info no-image" v-if="!loading">
    <div class="course-content fadeIn" v-if="book">
      <div class="course-top">
        <i class="i-icon course-cover" :style="{ backgroundImage: 'url(' + book.extension_info?.cover + ')' }"></i>
        <div class="info-box">
          <div class="info-text" v-if="book.is_unique_book">{{ isPaid ? "已购" : "激活" }}教材</div>
          <div class="info-text" v-else>{{ isPaid ? "已购" : "购买" }}教材</div>
          <div class="info-title">{{ book.name }}</div>
          <div>{{ book.publisher }}</div>
          <div class="courseprice">
            <span v-if="book.is_unique_book">内测版本</span>
            <span v-else>￥{{ book.price }}</span>
            <!-- <span>/{{ book.unitCount }}章节</span> -->
          </div>
          <a-button class="buy-button" type="primary" size="large" @click="login()" v-if="!userInfo">请先登录</a-button>
          <a-button class="buy-button" type="primary" size="large" @click="buy()" v-else-if="!isPaid">立即{{
            book?.is_unique_book ? '激活' : '购买' }}</a-button>
          <a-button class="buy-button" type="primary" size="large" @click="study()" v-else>进入学习</a-button>
        </div>
      </div>

      <div class="course-menu-box">
        <div class="course-menu">
          <div class="menu-title" :class="{ actived: activeMenu === item }" v-for="item in menuList"
            @click="activeMenu = item">{{ item }}</div>
        </div>
        <template v-if="activeMenu === '简介'">
          <div class="course-content ck-editor" v-html="book.extension_info?.introduce"
            v-if="book.extension_info?.introduce">
          </div>
          <div class="empty-box" v-else>
            <img :src="empty_image" alt />
            <div>暂无简介</div>
          </div>
        </template>
        <template v-if="activeMenu === '目录'">
          <div class="catalog" v-if="book.catalog">
            <div class="catalog-item" v-for="int in book.catalog">
              <div class="catalog-title">{{ int.title }}</div>
              <div class="nav-item" :class="'h' + oitem.level" v-for="oitem in int.children">{{
                oitem.content }}
              </div>
            </div>
          </div>
          <div class="empty-box" v-else>
            <img :src="empty_image" alt />
            <div>暂无目录</div>
          </div>
        </template>
      </div>
      
      <!-- <div class="other-course-box">
        <div class="other-title">推荐教材</div>
        <div class="other-course">
          <div class="course-box">
            <div class="course-item" v-for="course of allStoreBooks" @click="chooseCourse(course)"
              :style="{ backgroundImage: 'url(' + book.extension_info?.cover + ')' }">
            </div>
          </div>
          <div class="other-page other-left" @click="otherListScroll('left')">
            <svg class="svg-icon">
              <use xlink:href="#icon-last" />
            </svg>
          </div>
          <div class="other-page other-right" @click="otherListScroll('right')">
            <svg class="svg-icon">
              <use xlink:href="#icon-next" />
            </svg>
          </div>
        </div>
      </div> -->
    </div>
    <foot></foot>
  </div>
</template>

<script>
import navroot from "@/component/public/nav/nav.vue";
import foot from "@/component/public/foot/foot.vue";
import infoBackground from "@/component/public/info-background/info-background.vue";

import router from '@/router';
import moment from 'moment';

export default {
  name: 'course-info',
  components: {
    navroot,
    foot,
    infoBackground
  },
  data() {
    return {
      pk_store_book_id: '',

      platformUrl: '',
      empty_image: require('@/assets/images/empty.png'),
      isPaid: false,
      loading: true,

      book: null,
      userInfo: null,
      vipInfo: null,
      currentStore: null,

      allStoreBooks: [],

      activeMenu: "简介",
      menuList: ['简介', '目录'],

      menuGrade: [
        { name: '1级', value: 'h0', show: true, disabled: true },
        { name: '2级', value: 'h1', show: true },
      ],

      idx: 0,
      num: 7,
      showcouser: true,
      alshow: false,

      phoneMode: false
    }
  },
  mounted() {
    let content = document.getElementById('content');
    if (content) content.scrollTo({ top: 0, behavior: 'smooth' });
    this.$store.commit('showReturn', true);

    this.pk_store_book_id = this.$route.params.id;
    const { userInfo, storeBooks, phoneMode, currentStore } = this.$store.state;
    this.phoneMode = phoneMode;
    this.userInfo = userInfo;
    this.currentStore = currentStore;

    this.allStoreBooks = storeBooks.filter(item => item.pk_store_book_id != this.pk_store_book_id);
    this.book = storeBooks.find(item => item.pk_store_book_id == this.pk_store_book_id);
    if (!this.book) return;

    this.loading = false;
    if (this.userInfo) this.findOrder();

    this.getCatalog();
  },
  methods: {
    //登录
    login() {
      this.$router.push('/login');
    },
    //购买
    buy() {
      router.push(`/payment/${this.pk_store_book_id}`);
    },
    //学习
    study() {
      const url = `/bookReader/${this.pk_store_book_id}`;
      window.open(url);
    },
    //跳转
    chooseCourse(item) {
      window.location.href = `/course-info/${item.pk_store_book_id}`;
    },
    //判断是否有购买订单或vip是否过期
    async findOrder() {
      const { system_id, is_unique_book, price } = this.book;
      const res = await this.$api.payorder.findPayOrder({ system_id, fk_userGuid: this.userInfo.PK_UserGuid });
      if (res.statusCode != 200) return this.$message.error(res.error);
      if (res.data && res.data.isPaid) return this.isPaid = true;
      if (is_unique_book === 1) return

      //vip是否过期
      const studentVip = this.$store.state.studentVip;
      if (studentVip) {
        if (!studentVip.deadline || new Date() < new Date(studentVip.deadline)) this.isPaid = true;
        else {
          this.$Modal.confirm({
            okText: '我知道了',
            cancelText: ' ',
            title: `很抱歉，您的VIP已于${moment(studentVip.deadline).format('YYYY-MM-DD')}过期。请续期VIP或单独购买数字教材使用。`,
            onOk: () => {
              this.$Modal.destroyAll();
            }
          })
        }
      }
    },

    //提取目录
    async getCatalog() {
      const { fk_publishing_id, repository, single_url } = this.book;
      if (single_url) return;
      
      const fileContent = await this.$api.bookManage.getFileContent(`${repository.url}/${fk_publishing_id}`).then(res => res.data);
      if (!fileContent) return;
      const { list } = fileContent;
      list.forEach(int => {
        if (int.TextbookContent) {
          const textbookContent = JSON.parse(int.TextbookContent);
          const content = textbookContent.content;

          //使用正则表达式提取标题标签
          const regex = /<h[1-6][^>]*>(.*?)<\/h[1-6]>/g;
          const matches = content.match(regex);
          let newMatches = [];
          if (matches) {
            matches.forEach((item) => {
              const levels = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
              for (const level of levels) {
                if (item.includes(level)) {
                  item = `${this.removeHTMLTags(level, item)}`;
                  newMatches.push(item);
                }
              }
            })
          }
          int.showTextbookContent = newMatches.join("");
        }
      })
      this.setMindmap(list);
    },
    //去除html
    removeHTMLTags(level, html) {
      var tmp = document.createElement("div");
      tmp.innerHTML = html;
      let id = $(html).attr('data-id')
      if (!id) return '';

      id = id.replaceAll('\\"', '');
      let text = tmp.textContent || tmp.innerText || "";
      return `<${level} nav-data-id="${id}">${text}</${level}>`
    },
    setMindmap(list) {
      const catalog = [];
      list.forEach(int => {
        let outline = {
          id: int.PK_InductionGuid,
          title: int.InductionName,
          children: []
        }

        let children = [];
        if (int.showTextbookContent) {
          let content = int.showTextbookContent;
          let dom = document.createElement('div');
          dom.innerHTML = content;
          //按照content标签顺序获取
          let showGrade = [];
          this.menuGrade.forEach(grade => {
            if (grade.show) showGrade.push(grade.value);
          })
          if (showGrade.length > 0) {
            let showGradeString = showGrade.join(',');
            let headers = dom.querySelectorAll(showGradeString);
            headers.forEach(h => {
              let level = h.tagName.toLowerCase().replace('h', '');
              let content = h.innerText;
              children.push({
                level,
                content,
                id: h.getAttribute('nav-data-id'),
              })
            })
          }
        }
        outline.children = children;
        catalog.push(outline);
      })
      this.book.catalog = catalog;
    },


    //滚动条移动
    otherListScroll(type) {
      let scroll = $('.course-box').scrollLeft();
      if (type == 'left') $('.course-box').animate({ scrollLeft: scroll - 800 }, 300)
      else if (type == 'right') $('.course-box').animate({ scrollLeft: scroll + 800 }, 300)
    },
  }
}
</script>

<style scoped lang="scss">
@import "./course-info.scss";
</style>