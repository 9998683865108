<template>
  <div class="mycourse fadeIn">
    <i class="lucidscreen"></i>
    <div class="course-box">

      <div class="course-item" :style="{ backgroundImage: 'url(' + course.extension_info.cover + ')' }"
        v-for="course of storeBooks" :key="course.fk_class_id" @click="chooseCourse(course)">
        <div class="course-top">
          <a-tooltip title="详情">
            <div class="svg-box info" @click.stop="routerPath(`/course-info/${course.pk_store_book_id}?from=mycourse`)">
              <svg class="icon-svg">
                <use xlink:href="#icon-info"></use>
              </svg>
            </div>
          </a-tooltip>
        </div>

        <div class="course-bottom">
          <div class="join-class-box">
            <div class="join-class" v-if="!course.isJoinClass">
              <a-tooltip title="加入教材班">
                <div class="svg-box" @click.stop="openJoinClass(course)">
                  <svg class="icon-svg">
                    <use xlink:href="#icon-join-class"></use>
                  </svg>
                </div>
              </a-tooltip>
              <div class="join-input" :class="{ show: course.joining }" @click.stop>
                <input type="text" v-model="course.invitation_code" placeholder="请输入邀请码" :maxLength="6" v-focus
                  @keyup.enter="searchClass(course)">
                <svg class="icon-svg" @click="searchClass(course)">
                  <use xlink:href="#icon-sousuo"></use>
                </svg>
              </div>
            </div>
            <div class="join-class joined" v-else>
              <a-tooltip title="已加入教材班">
                <div class="svg-box">
                  <svg class="icon-svg">
                    <use xlink:href="#icon-student-count"></use>
                  </svg>
                </div>
              </a-tooltip>
            </div>
          </div>
          <div class="course-status" :class="{ finished: course.isFinished, hide: course.joining }">
            <div v-if="course.isFinished">
              <svg class="svg-icon">
                <use xlink:href="#icon-duihao" />
              </svg>
              <span>已完成</span>
            </div>
            <div v-else>
              <svg class="svg-icon">
                <use xlink:href="#icon-time" />
              </svg>
              <span>进行中</span>
            </div>
          </div>
        </div>
      </div>

      <div class="empty-box" v-if="storeBooks.length == 0">
        <img :src="empty_image" alt />
        <div>暂无购买教材，请前往教材商店</div>
        <a-button type="primary" size="large" class="lp-button" @click="routerPath('/home')">教材商店</a-button>
      </div>
    </div>
    <foot></foot>

    <JoinClass :joinClass="joinClass" @closebox="joinClass = null" @joinSuccess="joinBookClass()" v-if="joinClass">
    </JoinClass>
  </div>
</template>

<script>
import foot from "@/component/public/foot/foot.vue";
import navroot from "@/component/public/nav/nav.vue";
import JoinClass from "@/component/mycourse/JoinClass.vue";

export default {
  components: {
    foot,
    navroot,
    JoinClass
  },
  data() {
    return {
      storeBooks: [],

      empty_image: require("@/assets/images/empty.png"),
      backgroundImage: require("@/assets/images/my-book/background.jpg"),
      keyword: "",

      userInfo: null,
      userGuid: "",

      finishStatus: null,

      loading: true,
      vipPastdue: false,
      updateDgUser: false,
      phoneMode: false,
      config: null,

      isSearching: false,
      joinClass: null,
    };
  },
  computed: {

  },
  mounted() {
    const { userInfo, storeBooks, studentVip, phoneMode } = this.$store.state;

    if (!userInfo) {
      this.$router.push('/login');
    }

    this.userInfo = userInfo;
    this.userGuid = userInfo.PK_UserGuid;

    this.phoneMode = phoneMode;
    this.$store.commit('showReturn', true);

    //判断是否是vip过期
    if (studentVip) this.vipPastdue = studentVip.isPastdue;

    let newStoreBooks = storeBooks.filter((item) => item.isPaid);
    this.storeBooks = JSON.parse(JSON.stringify(newStoreBooks));

    this.getStuRecord();
  },
  methods: {
    //查询学生所有记录
    async getStuRecord() {
      let res = await this.$api.unifydata.getStudentRecord();
      if (res.statusCode != 200) return this.$message.error(res.message);
      let commitList = res.data;

      this.storeBooks.forEach((item, i) => {
        item.isFinished = false;
        let commit = commitList.find((citem) => item.fk_class_id == citem.classId);

        if (commit) {
          let finishCode = commit.contents.length || 0;
          item.isFinished = finishCode >= item.codeCount;
        }
      })
    },
    //选择教材
    chooseCourse(item) {
      if (item.isPaid || !this.vipPastdue) {
        const { pk_store_book_id } = item;
        const url = `/bookReader/${pk_store_book_id}`;
        window.open(url);
      }

      let message = "很抱歉，您的购买已过期，请重新购买。";
      if (this.vipPastdue) message = `很抱歉，您的VIP已于${this.VIP_pastdue_date}过期。请续期VIP或单独购买数字教材使用。`;
      return this.$Modal.confirm({
        okText: "我知道了",
        cancelText: " ",
        title: message,
        onOk: () => {
          this.$Modal.destroyAll();
        },
      });
    },
    //跳转
    routerPath(url) {
      this.$router.push(url);
    },
    //加入教材班级
    openJoinClass(course) {
      course.joining = !course.joining;
      course.invitation_code = "";
    },
    //搜索班级
    async searchClass(course) {
      if (this.isSearching) return;
      this.isSearching = true;
      let code = course.invitation_code;
      if (code) code = code.trim();
      else return this.$message.warning('请输入邀请码');

      const res = await this.$api.bookManage.storeClassFindByCode(code);
      this.isSearching = false;
      const data = res.data;
      if (!data) return this.$message.warning('邀请码不正确');
      if (course.fk_publishing_id != data.fk_publishing_id) return this.$message.warning('班级与该教材不匹配');

      data.invitation_code = code;
      this.joinClass = data;
      this.openJoinClass(course);
    },
  },
};
</script>

<style scoped lang="scss">
@import "./mycourse.scss";
</style>