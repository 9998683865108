<template>
    <div class="new-popupbox fadeIn">
        <div class="hanlder-box">
            <svg class="icon-svg popup-close" @click="closebox">
                <use xlink:href="#icon-guanbi" />
            </svg>
        </div>
        <div class="class-item new-class-item fadeIn">
            <i class="item-cover" :style="{ backgroundImage: 'url(' + joinClass.store_book_cover + ')' }"></i>
            <div class="item-info">
                <div class="item-title">{{ joinClass.store_book_name }}</div>
                <div class="item-time">创建：{{ joinClass.store_book_createTime }}</div>
            </div>
            <div class="item-bottom">
                <div class="item-int">
                    <svg class="icon-svg int-icon">
                        <use xlink:href="#icon-student-count" />
                    </svg>
                    <span>{{ joinClass.stuNumber || 0 }}人</span>
                </div>
                <a-button class="enter-button" @click="joining" :loading="isJoining">加入训练</a-button>
            </div>
        </div>
    </div>
</template>
<script setup>
import { onMounted, ref } from 'vue'
import { message } from 'ant-design-vue'
import store from "@/store";
import api from "@/services";

const props = defineProps({
    joinClass: Object,
})
const emit = defineEmits(['joinSuccess', 'closebox'])

const isJoining = ref(false);
const userGuid = ref('');

onMounted(() => {
    const { userInfo } = store.state;
    userGuid.value = userInfo.PK_UserGuid;
});

async function joining() {
    if (isJoining.value) return;
    isJoining.value = true;
    const res = await api.bookManage.bookUserJoinClass({
        fk_user_id: userGuid.value,
        invitation_code: props.joinClass.invitation_code
    })
    isJoining.value = false;
    if (res.statusCode != 1) return message.warning(res.message);

    message.success('加入班级成功');
    emit('joinSuccess');
};

function closebox() {
    emit('closebox');
}

</script>

<style lang="scss" scoped>
.new-popupbox {
    .class-item {
        width: 300px;
        padding: 25px 20px;
        border-radius: 10px;
        margin: 10px;
        transition: all .3s ease;
        cursor: pointer;
        background-color: #214194cf;
        box-shadow: $box-shadow;

        .item-cover {
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
            border-radius: 10px;
            height: 300px;
            width: 100%;
            display: block;
        }

        .item-info {
            padding: 20px 0;

            .item-title {
                font-size: 20px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }

        .item-bottom {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .item-int {
                display: flex;
                align-items: center;
                font-size: 15px;

                .int-icon {
                    margin-right: 5px;
                    font-size: 16px;
                }
            }

            .enter-button {
                width: 100px;
                background-color: #ffffff;
                color: #214194;
                border: none;
            }
        }
    }

    .enter-button {
        background-color: #333333;

        &:hover {
            opacity: 0.8;
        }
    }
}
</style>