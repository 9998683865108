<template>
  <div class="home-box fadeIn">
    <template v-if="normalRender">
      <div class="title-box">
        <div class="main-title">德才数字出版云平台</div>
        <a-input-search class="input-search" v-model:value="keyword" placeholder="请输入教材名称搜索" @search="onSearch" />
      </div>
      <!-- <div class="recommended-box">
        <div class="recommended" ref="recommended" @mouseenter="rdMouse('enter')" @mouseleave="rdMouse('leave')">
          <div class="rd-item rd-pre" :class="{ hide: rdList.length < 4 }"
            :style="{ backgroundImage: 'url(' + rdLast.extension_info?.cover + ')' }" v-if="rdLast"
            @click="rdHolder(rdList.length - 1)">
          </div>
          <div class="rd-item" :ref="'rd_' + i" :class="{ actived: rdActive == i && !phoneMode }"
            :style="{ backgroundImage: 'url(' + rd.extension_info?.cover + ')', backgroundColor: (rdActive == i && !phoneMode) ? rd.primaryColor : 'transparent' }"
            v-for="(rd, i) of rdList" @click="rdChoose(i, '')">
            <div class="rd-info" v-if="rdActive == i && !phoneMode">
              <div class="rd-price">
                <span v-if="rd.isPaid || rd.hasJoin">已购买</span>
                <span v-else-if="rd.is_unique_book">内测版本</span>
                <span v-else>¥{{ rd.price }}</span>
              </div>
              <div class="rd-title ellipsis">{{ rd.name }}</div>
              <div class="rd-subTitle ellipsis">{{ rd.publisher }}</div>
            </div>
          </div>

          <template v-if="rdList.length > 4">
            <div class="rd-item rd-last" :style="{ backgroundImage: 'url(' + rd.extension_info?.cover + ')' }" v-for="(rd, i) of rdList"
              @click="rdHolder(i)">
            </div>
          </template>
</div>
<div class="recommended-dot" v-if="!phoneMode">
  <div class="dot-box" :class="{ actived: rdActive == i }" v-for="(rd, i) of rdList" @click="rdChoose(i, 'dot')">
    <i class="dot"></i>
  </div>
</div>
</div> -->
      <div class="course">
        <div class="catalog-box" v-if="catalogList.length > 1">
          <div class="catalog">
            <div class="catalog-item" :class="{ selected: catActive == i, noicon: !catalog.icon }"
              v-for="(catalog, i) of catalogList" @click="chooseCatalog(i)">
              <i class="catalog-icon" :style="{ backgroundImage: 'url(' + catalog.icon + ')' }" v-if="catalog.icon"></i>
              <span class="catalog-title">{{ catalog.name }}</span>
            </div>
          </div>
          <div class="catalog-page catalog-left" @click="catalogScroll('left')">
            <svg class="svg-icon">
              <use xlink:href="#icon-last" />
            </svg>
          </div>
          <div class="catalog-page catalog-right" @click="catalogScroll('right')">
            <svg class="svg-icon">
              <use xlink:href="#icon-next" />
            </svg>
          </div>
        </div>

        <div class="course-box">
          <div class="course-item-box" :class="{ hide: i > 9 }" v-for="(course, i) of showList"
            @click="routerCourse(course)">
            <div class="course-item" :style="{ backgroundImage: 'url(' + course.extension_info.cover + ')' }">
              <div class="course-top" @click.stop>
                <a-tooltip title="详情">
                  <div class="svg-box info" @click="routerPath(`/course-info/${course.pk_store_book_id}`)">
                    <svg class="icon-svg">
                      <use xlink:href="#icon-info"></use>
                    </svg>
                  </div>
                </a-tooltip>
              </div>
            </div>
            <div class="course-name">{{ course.name }}</div>
          </div>
          <emptyBox warning="Sorry,暂无此类教材" v-if="storeBooks.length == 0"></emptyBox>
        </div>

        <div class="course-bottom" v-if="storeBooks.length >= pageSize">
          <div class="pagination pagination-left" @click="changePage(page - 1)">
            <svg class="svg-icon">
              <use xlink:href="#icon-last" />
            </svg>
          </div>
          <div class="pagination pagination-center" @click="routerPath('/all-course')"
            v-if="catalogList[catActive]?.id == ''">
            全部
          </div>

          <div class="pagination pagination-right" @click="changePage(page + 1)">
            <svg class="svg-icon">
              <use xlink:href="#icon-next" />
            </svg>
          </div>
        </div>

      </div>

      <div class="home-info">
        <div class="info-text ck-editor" v-html="info"></div>
        <img class="info-img" src="@/assets/images/home/info.png" />
      </div>
      <foot v-if="!phoneMode"></foot>
    </template>
  </div>
</template>

<script>
import foot from "@/component/public/foot/foot.vue";
import router from "@/router";
import navroot from "@/component/public/nav/nav.vue";
import emptyBox from "@/component/public/empty-box/empty-box.vue";

export default {
  name: "home",
  components: {
    navroot,
    foot,
    emptyBox,
  },
  data() {
    return {
      catalogList: [],
      catActive: 0,

      allStoreBooks: [],
      storeBooks: [],
      showList: [],

      page: 1,
      pageSize: 10,
      maxPage: 1,
      needScroll: false,
      listenerWindow: true,

      rdList: [],
      rdLast: null,
      rdSlideshow: 3000,
      rdActive: 0,
      rdInterval: null,

      pageTemplate: null,
      navContent: null,
      newOpenBook: null,
      keyword: "",

      info: '<p style="text-align:center;font-size:30px;padding-bottom:20px;"><span class="text-huge"><strong>科学出版社中科数字出版传媒有限公司</strong></span></p><p  style="text-indent:2em"><span>中科数字出版传媒有限公司是中国科技出版传媒股份有限公司（科学出版社）负责数字化转型业务的全资子公司。公司通过融合出版和信息化技术，面向机构和个人提供知识服务、数字出版、音像出版、数字化产品制作、信息化技术研发与服务、期刊等业务。在出版业数字化转型的浪潮中，公司培养了一支专业、高效的信息化服务团队，推出不同类型的数字化产品，积极推动数字化教学和科技出版转型升级。</span></p>',

      userInfo: null,
      vipPastdue: true,

      loadding: true,
      phoneMode: false,
      updateDgUser: false,
      normalRender: true,
    };
  },
  mounted() {
    //监听store
    this.$store.subscribe((res) => {
      if (!res) return;

      let { type, payload } = res;
      if (type == "phoneMode") this.phoneMode = payload;
    })

    if (this.$store.state.isElectron) {
      const electronConfig = this.$store.state.electronConfig;
      if (!electronConfig.online) {
        // 无网
        if (!electronConfig.token) {
          this.$message.error({
            content: "请先联网(联网登录一次后可离线使用)",
            duration: 0,
            top: 200
          });
        } else {
          this.userGuid = electronConfig.userGuid;
          this.token = electronConfig.token;
          this.$router.replace("/download");
        }
        this.normalRender = false;
        return;
      }
    }

    this.setPageTemplate();
    this.judgeCardMove();
  },
  methods: {
    //设置pageTemplate
    setPageTemplate() {
      const {
        userInfo,
        storeBooks,
        phoneMode,
        studentVip,
        currentStore
      } = this.$store.state;

      this.userInfo = userInfo;
      this.allStoreBooks = JSON.parse(JSON.stringify(storeBooks));
      this.phoneMode = phoneMode;

      const typeGroups = currentStore.typeGroups;
      let catalogList = [{ name: "全部", id: "" }]
      if (typeGroups.length > 0) catalogList = [...catalogList, ...typeGroups[0].list];
      this.catalogList = catalogList;

      const rdList = storeBooks;
      this.rdLast = rdList[rdList.length - 1];
      this.rdList = rdList;

      //判断是否是vip过期
      if (studentVip) this.vipPastdue = studentVip.isPastdue;

      this.catActive = 0;
      this.chooseCatalog(this.catActive);

      //监听窗口变化
      this.isPhoneMode();
      window.addEventListener("resize", this.isPhoneMode);
    },
    //是否是手机端
    isPhoneMode() {
      if (!this.listenerWindow) return;
      this.listenerWindow = false;
      setTimeout(() => { this.listenerWindow = true }, 3000);

      const { phoneMode } = this.$store.state;
      this.phoneMode = phoneMode;

      // //去除轮训
      // if (this.rdInterval) clearInterval(this.rdInterval);
      // //自动轮播
      // setTimeout(() => {
      //   if (!phoneMode) {
      //     if (this.rdList.length > 1) {
      //       this.rdAutoMove();
      //     }
      //   } else this.pageSize = 6;
      // }, 100);
    },

    ////////////////////////////////推荐轮播
    //选择推荐
    rdChoose(i, type) {
      let rdActive = this.rdActive;
      if ((i == rdActive && type != "dot") || this.phoneMode) {
        return this.routerCourse(this.rdList[i]);
      }

      let dom = this.$refs.recommended;
      if (!dom) return;
      dom.style.transitionDuration = `1s`;

      let item_dom = this.$refs[`rd_${i}`];
      if (item_dom > 0) item_dom[0].style.transition = `all .7s ease`;

      let old_item_dom = this.$refs[`rd_${rdActive}`];
      if (old_item_dom > 0) old_item_dom[0].style.transition = `all .7s ease`;

      //恢复
      setTimeout(() => {
        dom.style.transitionDuration = `.3s`;
        if (item_dom.length > 0) item_dom[0].style.transition = `all .3s ease`;
        if (old_item_dom > 0) old_item_dom[0].style.transition = `all .3s ease`;
      }, 1000);

      this.rdMove(i);
    },
    //选择占位
    rdHolder(i) {
      let rdActive = this.rdActive;
      if (i == rdActive) return;

      this.rdMove(i);
    },
    //rd鼠标移入移出
    rdMouse(type) {
      if (this.phoneMode) return;
      if (type == "enter") {
        window.addEventListener("mousewheel", this.rdMousewheel);
        $("#content").css("overflow-y", "hidden");
        $("#content").css("padding-right", "10px");
      } else {
        window.removeEventListener("mousewheel", this.rdMousewheel);
        $("#content").css("overflow-y", "auto");
        $("#content").css("padding-right", "0px");
      }
    },
    //监听鼠标滚轮
    rdMousewheel(e) {
      let direction = e.deltaY > 0 ? "down" : "up";
      let rdActive = this.rdActive;
      let rdList = this.rdList;

      if (direction == "down") {
        if (rdActive == rdList.length - 1) this.rdMove(0);
        else this.rdMove(rdActive + 1);
      } else if (direction == "up") {
        if (rdActive == 0) this.rdMove(rdList.length - 1);
        else this.rdMove(rdActive - 1);
      }
    },
    //轮播图移动
    rdMove(i) {
      if (this.rdInterval) clearInterval(this.rdInterval);

      this.rdActive = i;
      let dom = this.$refs.recommended;
      if (!dom) return;
      let left_ts = i ? i * 280 + 350 : 350;
      dom.style.transform = `translate3d(-${left_ts}px, 0px, 0px)`;

      this.rdAutoMove();
    },
    //轮播图自动滚动
    rdAutoMove() {
      this.$nextTick(() => {
        let rdActive = this.rdActive;
        let rdList = this.rdList;

        if (this.rdInterval) clearInterval(this.rdInterval);
        this.rdInterval = setInterval(() => {
          if (rdActive == rdList.length - 1) this.rdChoose(0, "");
          else this.rdChoose(rdActive + 1, "");
        }, this.rdSlideshow);
      })
    },
    ////////////////////////////////分类
    //选择分类
    chooseCatalog(i) {
      this.catActive = i;

      //关键词
      let storeBooks = this.allStoreBooks.filter((item) => item.name.indexOf(this.keyword) > -1);
      const catalog = this.catalogList[i];

      if (catalog.id) {
        storeBooks = storeBooks.filter((item) => item.types.indexOf(catalog.id) > -1);
      }
      console.log(storeBooks);

      this.storeBooks = storeBooks;
      this.maxPage = Math.ceil(this.storeBooks.length / this.pageSize);

      this.needScroll = false;
      this.changePage(1);

      this.loadding = false;
    },
    //翻页
    changePage(page) {
      if (page <= 0) return this.$message.warning("已经是第一页了");
      else if (page > 1 && page > this.maxPage) return this.$message.warning("已经是最后一页了");
      this.page = page;

      let pageSize = this.pageSize;
      let storeBooks = JSON.parse(JSON.stringify(this.storeBooks));
      this.showList = storeBooks.slice((page - 1) * pageSize, page * pageSize);
      this.page = page;

      if (this.needScroll) {
        if (this.phoneMode) {
          $("#content").animate({ scrollTop: 450 }, 300);
        } else {
          $("#content").animate({ scrollTop: 870 }, 300);
        }
      }
      this.needScroll = true;
    },
    //滚动条移动
    catalogScroll(type) {
      let scroll = $(".catalog").scrollLeft();
      if (type == "left")
        $(".catalog").animate({ scrollLeft: scroll - 700 }, 300);
      else if (type == "right")
        $(".catalog").animate({ scrollLeft: scroll + 700 }, 300);
    },

    //跳转
    routerCourse(item) {
      if (item.isPaid || (!this.vipPastdue && !item.is_unique_book)) {
        this.study(item);
      }
      else {
        $("#content").css("overflow-y", "auto");
        $("#content").css("padding-right", "0px");
        router.push(`/course-info/${item.pk_store_book_id}`);
      }
    },
    //学习
    study(item) {
      const { pk_store_book_id } = item;
      const url = `/bookReader/${pk_store_book_id}`;
      window.open(url);
    },

    //关键词搜索
    onSearch() {
      this.chooseCatalog(this.catActive);
    },
    //跳转
    routerPath(url) {
      this.$router.push(url);
    },
    //////////////////////////////卡片轮播
    //判断初始card位移
    judgeCardMove() {
      this.cardTransform = 1150 - window.innerWidth / 2;

      setTimeout(() => {
        this.cardMove(0);
      }, 1000);

      let that = this;
      $(window).resize(function () {
        that.cardTransform = 1150 - window.innerWidth / 2;
        that.cardMove(that.cardActive);
      });
    },
    //选择卡片
    cardChoose(i) {
      if (this.phoneMode) return;

      let cardActive = this.cardActive;
      if (i == cardActive) return;

      let dom = this.$refs.card;
      if (!dom) return;
      dom.style.transitionDuration = `.3s`;
      //恢复
      setTimeout(() => {
        dom.style.transitionDuration = `0s`;
      }, 400);

      this.cardMove(i);
    },
    //选择占位
    holderCard(i) {
      let cardActive = this.cardActive;
      if (i == cardActive) return;

      this.cardMove(i);
    },
    //轮播图移动
    cardMove(i) {
      this.cardActive = i;
      let dom = this.$refs.card;
      let ctf = this.cardTransform;
      if (!dom) return;

      let left_ts = 0 - (i * 440 + ctf + 20);
      dom.style.transform = `translate3d(${left_ts}px, 0px, 0px)`;
    },
  },
  beforeDestroy() {
    //去除轮训
    if (this.rdInterval) clearInterval(this.rdInterval);
    if (this.columnInterval) clearInterval(this.columnInterval);
  },
};
</script>

<style lang="scss" scoped>
@import "./home.scss";
</style>
