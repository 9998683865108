<template>
  <div class="foot-box">
    <div class="foot">
      <div class="area-box">
        <div class="logo-box">
          <div class="title">服务中心</div>
          <div class="more-text">地址：北京市东城区东黄城根北街16号</div>
          <div class="more-text">邮编：100717</div>
        </div>
        <div class="other-link">
          <div class="box-item" v-for="menu of menuList">
            <div class="title">{{ menu.title }}</div>
            <div class="menu-list">
              <div class="menu-item" v-for="item of menu.list" @click="chooseMenu(menu.type, item)">{{ item.name }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 公安备案 -->
    <div class="info-box">
      <div class="copyright">Copyright  2011-2023 中科数字出版传媒有限公司 版权所有  ICP  17034810 号-5 <span class="canClick" @click="openUrl('http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010102002940')">京公网安备 11010102002940 号</span>    地址: 北京市东城区东黄城根北街16号</div>
    </div>

    <div class="popup-box" v-if="qrcode">
      <div class="blackscreen" @click="qrcode = ''"></div>
      <div class="box-content">
        <img :src="qrcode" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'foot',
  data() {
    return {
      menuList: [
        {
          title: '友情链接',
          type: 'link',
          list: [
            { name: '中国科学院', url: 'https://www.cas.cn' },
            { name: '科学出版社', url: 'http://www.sciencep.com' },
            { name: '中科数媒官网', url: 'https://cspmmed.com' },
            { name: '中科医库', url: 'https://www.scipmed.com' }
          ]
        },
      ],


      qrcode: ''
    }
  },
  mounted() {
    //监听store
    // this.$store.subscribe((res) => {
    //   if (!res) return;

    //   let { type, payload } = res;

    // })
  },
  methods: {
    //选择菜单
    chooseMenu(type, item) {
      if (type == 'link') window.open(item.url);
      else this.qrcode = require(`@/assets/images/home/qrcode/${item.name}.jpg`);
    },
    //打开出版物经营许可证
    openCode(url) {
      this.qrcode = url;
    },
    //打开链接
    openUrl(url) {
      window.open(url);
    }
  }
}
</script>
<style scoped lang="scss">
@import "./foot.scss";
</style>